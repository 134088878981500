exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-knowledge-center-js": () => import("./../../../src/templates/knowledge-center.js" /* webpackChunkName: "component---src-templates-knowledge-center-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

