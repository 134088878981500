import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useThemeSettingsContext } from "context/page-info-context"

const CustomCookieConsent = ({ cookies, cookieDays }) => {
  const location = useLocation()
  if (typeof window === "undefined") {
    return null
  }
  const [displayCookieNotice, setDisplayCookieNotice] = useState(false)
  initializeAndTrack(location)

  const themeSettings = useThemeSettingsContext()
  if (!themeSettings) {
    return null
  }
  const {
    cookieBanner: { text, acceptText = "Accept", rejectText = "Reject" },
  } = themeSettings

  const onlyTechnicalCookies = cookies.every(
    cookie => cookie.name === "technical-cookies"
  )

  if (!text) {
    return null
  }

  let date = new Date()
  date.setTime(date.getTime() + cookieDays * 24 * 60 * 60 * 1000)
  const expires = `; expires=${date.toUTCString()}`

  const acceptCookies = () => {
    cookies.forEach(cookie => {
      document.cookie = `${cookie.name}=true${expires}; path=/`
    })
    initializeAndTrack(location)
    setDisplayCookieNotice(false)
  }

  const declineCookies = () => {
    cookies.forEach(cookie => {
      document.cookie = `${cookie.name}=false${expires}; path=/`
    })
    initializeAndTrack(location)
    setDisplayCookieNotice(false)
  }

  const cookieExists = cookieName => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${cookieName}=`)
    if (parts.length === 2) {
      return parts.pop().split(";").shift()
    }
    return null
  }

  useEffect(() => {
    const someCookieMissing = cookies.some(
      cookie => cookieExists(cookie.name) === null
    )
    setDisplayCookieNotice(someCookieMissing)
  }, [cookies])

  return (
    <>
      {displayCookieNotice && (
        <div className="fixed bottom-0 left-0 z-50 w-full items-center justify-between gap-2 bg-gray-100 px-4 py-6 lg:flex lg:py-12">
          <div
            className="mb-8 lg:mb-0"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
          <div className="flex items-center justify-center gap-1 lg:mx-16">
            {!onlyTechnicalCookies && (
              <button
                className="mr-2 rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-600"
                onClick={declineCookies}
              >
                {rejectText}
              </button>
            )}
            <button
              className="rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-600"
              onClick={acceptCookies}
            >
              {acceptText}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomCookieConsent
