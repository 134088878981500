import { createContext, useContext } from "react"

export const PageInfoContext = createContext(undefined)

export const usePageInfoContext = () => {
  const context = useContext(PageInfoContext)
  if (!context) {
    throw new Error("usePageInfoContext must be used within a PageProvider")
  }
  return context
}

export const useThemeSettingsContext = () => {
  const context = usePageInfoContext()
  const themeSettings = context.data?.themeSettings?.themeSettings
  if (themeSettings) {
    return themeSettings
  }
  try {
    const defaultLanguage = context.data?.wpSettings?.defaultLanguage?.code
    const themeSettingsPage = context.data?.allWpThemeSettingsPage.nodes.find(
      page => page.language.code === defaultLanguage
    )
    return themeSettingsPage.themeSettings
  } catch {
    return null
  }
}
