import React from "react"
import { PageInfoContext } from "context/page-info-context"
import TailwindContext from "context/tailwind-context"
import CookieConsent from "components/cookie-consent/cookie-consent"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "./tailwind.config"

export const wrapPageElement = ({ element }) => {
  return (
    <PageInfoContext.Provider value={element.props}>
      <CookieConsent
        cookies={[
          { name: "technical-cookies", default: false, editable: true },
        ]}
        cookieDays={365}
      />
      {element}
    </PageInfoContext.Provider>
  )
}

const fullConfig = resolveConfig(tailwindConfig)

export const wrapRootElement = ({ element }) => {
  const { theme } = fullConfig
  return (
    <TailwindContext.Provider value={theme}>{element}</TailwindContext.Provider>
  )
}

export const onRouteUpdate = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0)
  }
}

export const shouldUpdateScroll = args => {
  return false
}
