import { createContext, useContext, useState, useEffect } from "react"

export const TailwindContext = createContext(undefined)

export const useTailwindContext = () => {
  const context = useContext(TailwindContext)
  return context
}

export const useCurrentScreenSize = () => {
  const tailwindContext = useTailwindContext()
  const [screenSize, setScreenSize] = useState("")
  const screens = tailwindContext?.screens

  useEffect(() => {
    const getCurrentScreenSize = () => {
      if (!screens) {
        return ""
      }
      const width = window.innerWidth
      let currentSize = ""
      for (const [size, breakpoint] of Object.entries(screens)) {
        if (width >= parseInt(breakpoint)) {
          currentSize = size
        }
      }
      return currentSize
    }

    const handleResize = () => {
      setScreenSize(getCurrentScreenSize())
    }

    handleResize()

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [screens])

  return screenSize
}

export const useBreakpointIsGreaterThan = breakpoint => {
  const screens = useTailwindContext()?.screens
  const [isGreaterThan, setIsGreaterThan] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (screens && screens[breakpoint]) {
        setIsGreaterThan(window.innerWidth > parseInt(screens[breakpoint]))
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [screens, breakpoint])

  return isGreaterThan
}

export const useBreakpointIsLessThan = breakpoint => {
  const screens = useTailwindContext()?.screens
  const [isLessThan, setIsLessThan] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (screens && screens[breakpoint]) {
        setIsLessThan(window.innerWidth < parseInt(screens[breakpoint]))
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [screens, breakpoint])

  return isLessThan
}

export const tw = (strings, ...values) =>
  String.raw({ raw: strings }, ...values)

export default TailwindContext
