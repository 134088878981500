import colors from "tailwindcss/colors"
const isDev = process.env.NODE_ENV === "development"
const plugins = []
if (isDev) {
  const debugScreensPlugin = require("tailwindcss-debug-screens")
  plugins.push(debugScreensPlugin)
}
const tailwindConfig = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
    "./gatsby-browser.js",
    "./gatsby-ssr.js",
  ],
  theme: {
    debugScreens: {
      positions: ["top", "left"],
    },
    container: {
      center: true,
      padding: {
        DEFAULT: "1rem",
        sm: "2rem",
        lg: "4rem",
        xl: "5rem",
        "2xl": "6rem",
      },
    },
    extend: {
      colors: {
        accent: "#e6001f",
        "accent-hover": "#c4001a",
        gray: {
          200: "#ededec",
          300: "#d1d1d1",
          400: "#a4a3a2",
          500: "#787878",
          600: "#585858",
          700: "#282828",
        },
        "background-overlay": colors.gray[900],
      },
      screens: {
        hh: { raw: "(min-height: 800px)" },
      },
    },
  },
  variants: {
    extend: {
      display: ["group-hover"],
      transitionProperty: ["hover", "focus"],
    },
  },
  plugins,
}

export default tailwindConfig
